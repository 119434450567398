import { useRouter } from 'next/router';
import styles from './Page404.module.scss';
import { Button } from '@/components/Button';
import { SettingsType, Variants } from '@/types';
import { fetchData } from '@/services/fetchData';
import { useEffect, useState } from 'react';
import { Loader } from '@/components/Loader/Loader';

const Page404 = () => {
  const router = useRouter();
  const [locales, setLocales] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    const fetchTranslations = async () => {
      const menu: SettingsType = await fetchData({
        url: `/settings/view`,
        locale: router.locale,
      });

      setLocales(menu.translations['/404']);
    };

    fetchTranslations();
  }, [router.locale]);

  if (!locales) return <Loader fullScreen />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.sign}>404</div>
      <p>{locales.title}</p>
      <p>{locales.desc}</p>
      <div className={styles.buttons}>
        <Button
          text={locales.homeButton}
          variant={Variants.Outline}
          onClick={() => router.push('/')}
          className={styles.main}
        />
        <Button
          text={locales.backButton}
          variant={Variants.Ghost}
          onClick={() => router.back()}
        />
      </div>
    </div>
  );
};

export default Page404;
